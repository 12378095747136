import classNames from 'classnames'
import React, { useState, useEffect, useRef, useMemo,useImperativeHandle } from 'react'

const Carousel = React.forwardRef((props,ref) => {
    const [classesArr, setClassesArr] = useState([]);
    const [CarouselList, setCarouselList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    let timer = useRef(null);
    const { data } = props;

    useEffect(() => {
        initClasses();
    }, [data])

    useEffect(() => {
        CarouselList.length && sliderAuto()
        return () => {
            if (timer.current) {
                clearInterval(timer.current)
            }
        }
    }, [CarouselList])


    useImperativeHandle(ref,()=>({
        resetState:()=>{
            setCurrentIndex(0);
        }
    }))

    let list = useMemo(() => { return data || [] }, [data]);
    const initClasses = () => {
        const indexArr = list.map((item, index) => `${`item${index}`}`);
        setClassesArr(indexArr);
        setCarouselList(list)
    }

    const startTimer = () => {
        if (timer.current) {
            clearInterval(timer.current)
        }
        timer.current = setInterval(() => {
            toNext();
        }, 2000)
    }

    const endTimer = () => {
        clearInterval(timer.current)
    }

    const sliderAuto = () => {
        startTimer();
    }

    const toNext = () => {
        setClassesArr(prevState => {
            let arr = [...prevState];
            arr.unshift(arr.pop())
            return arr;
        });
        setCurrentIndex(prevState=>prevState >= CarouselList.length -1  ? 0: prevState + 1 )
    }

    const toPrev = () => {
        setClassesArr(prevState => {
            let arr = [...prevState];
            arr.push(arr.shift())
            return arr;
        });
        setCurrentIndex(prevState=>prevState <= 0 ? CarouselList.length -1 : prevState - 1 )
    }
    const lineClick=(index)=>{
        if(index < currentIndex){
            for(var i=0;i<currentIndex-index;i++){
                toPrev();
            }
        }else{
            for(var i=0;i<index-currentIndex;i++){
                toNext();
            }
        }
    }

    return (

        <div className="bannerWrapper" onMouseEnter={endTimer} onMouseLeave={startTimer}>
            <div className="carousel__container" >
                {
                    CarouselList.map((item, index) => {
                        return (
                            <div className={classNames('carousel__item', [classesArr[index]])} key={index}>
                                <img src={item} alt="" />
                            </div>
                        )
                    })
                }
            </div>
            <div className="line">
                {
                    CarouselList.map((item,index)=>{
                        return <span key={index} onClick={()=>lineClick(index)} className={classNames('line-item',{'line-cur':currentIndex == index})}></span>
                    })
                }
            </div>
        </div>
    )
})

export default Carousel
