import React from 'react'
const Page = (props) => {
    return (
        <div className="page pad-lg pt40 pb60">
            <div className="wrapper">
                <div className="logo">
                    <img src={require('@/assets/images/web-logo.png').default} alt="" />
                </div>
                <div className="title">隱私政策</div>
            </div>
            <div className="f-line"></div>
            <div className="info fz16 lh30">
                <div className="cont mt8">
                    <p className="pt30"> HiReader（以下簡稱“本軟件”）非常重視用戶的隱私，您在使用本軟件及相關服務時，我們可能會收集和使用您的相關信息，請務必仔細閱讀並理解本隱私政策聲明（以下簡稱“本聲明”）。一旦您選擇使用，即表示您認可並接受本條款現有內容及其可能隨時更新的內容。我們希望通過本聲明向您說明，在使用我們的服務時，我們如何收集、使用、存儲和分享這些信息，以及我們為您提供的訪問、更新、控制和保護這些信息的方式。本聲明與您所使用的本軟件服務息息相關，希望您仔細閱讀，在需要時，按照本聲明的指引，作出您認為適當的選擇。如您不同意相關協議或其中的任何條款的，您應停止使用我們的產品和服務。 如果您未滿18周歲，請在法定監護人的陪同下閱讀本協議。</p>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">一、信息收集</div>
                    <div className="cont mt8">
                        <p className="pt20">1.1 註冊信息</p>
                        <p>為了向您提供更好的用戶服務，我們會在您自願選擇服務或提供信息的情況下系統自動存儲註冊信息。請您在註冊時及時、詳盡及準確的提供個人資料，並不斷更新註冊資料。所有原始鍵入的資料將引用為註冊資料。如果因註冊信息不真實而引起的問題，由您自行承擔相應的後果。請您不要將您的帳號、密碼轉讓或出借予他人使用。如您發現您的帳號遭他人非法使用，應立即聯系並通知我們進行處理。您可以通過手機號創建賬號，也可以根據自身需求選擇性別，便於我們可以給您提供專屬服務。</p>
                    </div>
                    <div className="cont mt8">
                        <p className="pt20">1.2 因您使用產品或服務獲取的信息</p>
                        <p>1） 日誌信息：當您使用本軟件的服務時，服務器會自動記錄一些信息，例如您對我們服務的使用情況、使用的語言以及訪問日期和時間等。</p>
                        <p>2） 設備信息：某些產品和/或服務包含唯一應用程序編號，當您安裝、激活、更新、卸載相關服務或當這些服務定期與本軟件通信（例如軟件的更新）時，系統會將此編號以及與安裝相關的信息（例如操作系統類型和應用程序版本號）發送給我們。且為了提供更好的服務與改善用戶體驗，本軟件可能會記錄硬件型號、操作系統版本號等信息。</p>
                        <p>3） 位置信息：當您使用具有定位功能的服務時，系統會處理有關設備的位置信息。</p>
                        <p>4） cookie等同類工具：cookie主要的功能是便於您使用本產品和服務，運用cookie技術，我們能夠幫助您省去重復信息的步驟、流程等，也可為您提供更加周到的個性化服務，並允許您設定您特定的服務選項。如您清楚相關數據操作，您可能無法使用由我們提供的對應服務、相應功能。因在您網站上投放廣告的緣故，第三方可能會在用戶的瀏覽器上放置和讀取 Cookie，也可能會使用網絡信標來收集信息。</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">二、信息使用</div>
                    <div className="cont mt8">
                        <p className="pt20">2.1 個性化服務</p>
                        <p>通過使用收集的部分信息，本軟件可能會與合作夥伴共同向您提供您所要求的服務或者共同向您展示或推送您可能感興趣的內容（包括但不限於廣告、郵件、通知等消息）。除了實現對您的個性化服務，還可以幫助我們改進本產品或相關服務，並用於開發新的產品或服務。在信息為本產品或相關服務所必須的情況下，您同意本軟件可與其分享必要的信息。並且，我們會要求其確保數據安全並且禁止用於任何其他用途。除此之外，本軟件不會向任何無關第三方提供或分享信息。</p>
                        <p className="pt20">2.2 統計信息</p>
                        <p>本軟件可能會對產品使用情況進行統計。同時，我們可能會與公眾分享這些統計信息，以展示我們服務的整體使用趨勢（這些統計信息不包含您的任何身份識別信息）。</p>
                        <p className="pt20">2.3 披露信息</p>
                        <p>1） 事先獲得您的授權；</p>
                        <p>2） 您使用共享功能；</p>
                        <p>3） 根據法律、法規、法律程序的要求或政府主管部門的強制性要求；</p>
                        <p>4） 以學術研究或公共利益為目的；</p>
                        <p>5） 為維護我們的合法權益，例如查找、處理欺詐或安全方面的問題；</p>
                        <p>6） 符合相關服務條款或使用協議的其他規定。</p>
                        <p>我們不會將您的個人信息轉讓給任何公司、組織和個人，但以下情況除外：</p>
                        <p>在獲取明確同意的情況下轉讓:獲得您的明確同意後，我們會向其他方轉讓您的個人信息;</p>
                        <p>在涉及合並、收購或破產清算時，如涉及到個人信息轉讓，我們會在要求新的持有您個人信息的公司、組織繼續受此隱私政策的約束，否則 我們將要求該公司、組織重新向您征求授權同意。
                            共享、轉讓、公開披露個人信息時事先征得授權同意的例外</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">三、信息安全</div>
                    <div className="cont mt8">
                        <p className="pt20">3.1 用戶信息的保護</p>
                        <p>我們已通過多種安全技術對與您相關的信息進行保護，除非事先獲得您的授權或本聲明另有規定外，不會將這些信息對外公開或向第三方提供（本軟件關聯公司除外）。我們不允許任何第三方以任何手段收集、編輯、出售或者無償傳播您的個人信息。任何用戶如從事上述活動，一經發現，我們有權立即終止與該用戶的服務協議。</p>
                        <p className="pt20">3.2未成年人信息保護</p>
                        <p>我們非常重視對未成年人信息的保護，若您是18周歲以下的未成年人，在使用本產品或服務前，應事先取得您的家長或法定監護人的同意。</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">四、安全提醒</div>
                    <div className="cont mt8">
                        <p>1）互聯網並非絕對安全的環境，而且電子郵件、即時通訊、及與其他用戶的交流方式並未加密，我們強烈建議您不要通過此類方式發送個人信息。請使用復雜密碼，協助我們保證您的賬號安全。</p>
                        <p>2）互聯網環境並非百分之百安全，我們將盡力確保或擔保您發送給我們的任何信息的安全性。如果我們的物理、技術、或管理防護設施遭到破壞，導致信息被非授權訪問、公開披露、篡改、或毀壞，導致您的合法權益受損，我們將承擔相應的法律責任。</p>
                        <p>3）您在使用我們軟件服務時請勿將自認為隱私的信息發表、上傳，也不可將該等信息通過服務器傳播給他人，若因您該等行為引起隱私泄露，由您自行承擔責任。</p>
                        <p>4）您請勿在使用本軟件服務時公開透露自己的各類財產賬戶、銀行卡、信用卡、第三方支付賬戶及對應密碼等重要資料，否則由此帶來的損失由您自行承擔責任。</p>
                        <p>5）我們一旦發現假冒、仿冒、盜用他人名義進行平臺認證的，我們有權立即刪除用戶信息並有權在用戶提供充分證據簽禁止其使用平臺服務。</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">五、安全事件通知</div>
                    <div className="cont mt8">
                        <p>1）我們會制定相應的網絡安全事件應急預案，及時處置系統漏洞、計算機病毒、網絡攻擊、網絡侵入等安全風險，在發生危害網絡安全的事件時，我們會立即啟動應急預案，采取相應的補救措施。</p>
                        <p>2）在不幸發生個人信息安全事件後，我們將按照法律法規的要求，及時向您告知：安全事件的基本情況和可能的影響、我們已采取或將要采取的處置措施、您可自主防範和降低風險的建議、對您的補救措施等。我們將及時將事件相關情況以郵件、信函、電話、推送通知等方式告知您，難以逐一告知個人信息主體時，我們會采取合理、有效的方式發布公告。</p>
                        <p>3）如您發現自己的個人信息泄密，尤其是您的賬戶及密碼發生泄露，請您立即聯系我們，以便我們采取相應措施。</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">六、隱私政策的適用範圍</div>
                    <div className="cont mt8">
                        <p>除某些特定服務外，我們所有的服務均適用本隱私聲明，這些特定服務的隱私聲明將在特定服務產生時進行具體說明。特定服務的隱私聲明為本隱私聲明中的一部分，在特定服務的隱私政策與本隱私聲明有不一致之處，以特定服務的隱私聲明為準。</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">七、修訂</div>
                    <div className="cont mt8">
                        <p>我們可能適時會對本隱私權保護聲明或相關特定服務的隱私聲明進行修訂，並會隨本軟件的最新版本進行發布。如果我們隊本隱私聲明的修訂造成您權利的實質減少，您可以選擇停止使用本軟件及相應的服務。在該種情況下，若您繼續使用我們的服務，即表示您同意受經修訂的本聲明約束。</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">八、問題反饋</div>
                    <div className="cont mt8">
                        <p>如果您還有其他問題和建議，請聯系我們，我們會盡力保護您的隱私信息，並為您提供更優質的體驗和服務。</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Page
