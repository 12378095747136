import React from 'react';
import { useTranslation } from 'react-i18next'
let language ='en';
export const TranslateContext = React.createContext(null);

const Transition = (props) => {
    let {i18n}=useTranslation();
    language=i18n.language;
    return (
        <TranslateContext.Provider value={language}>
            {props.children}
        </TranslateContext.Provider>
    );
};

export default Transition;
