import React, { useContext } from 'react'
// import classNames from 'classnames'
import { TranslateContext } from '../context/translate'
import PrivacyCn from './components/privacyCn'
import PrivacyEn from './components/privacyEn'
const Page = (props) => {
    const tranlsate='en';
    return (
        <div>
            {
                (tranlsate === 'zh' || tranlsate.includes('zh')) ? <PrivacyCn /> : <PrivacyEn />
            }
        </div>
    )
}
export default Page
