import React from 'react'
import LogoImg from '@/assets/images/logo.png'
const Logo=() =>{
    return (
        <div className="flex items-center">
            <img src={LogoImg} className="w128" alt="" />
            {/* <div className="fz32 lh40 text-black ml20 fw800">HiReader</div> */}
        </div>
    )
}

export default Logo
