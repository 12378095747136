import React from 'react'
import dataRule from './termOfServiceText';
const Page = (props) => {
    return (
        <div className="page pad-lg pt40 pb60">
            <div className="wrapper">
                {/*<div className="logo">*/}
                {/*    <img src={require('@/assets/images/web-logo.png').default} alt="" />*/}
                {/*</div>*/}
                <div className="title">Terms of Service</div>
            </div>
            <div className="f-line"></div>

            <div className="wrap fz16 lh30 mt40">
                {Object.keys(dataRule).map((key) => {
                    return (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: dataRule[key],
                            }}
                            key={key}
                        ></p>
                    );
                })}
            </div>
        </div>
    )
}
export default Page
