import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const Footer = () => {
    let { t } = useTranslation()
    return (
        <div className="window-width footer pad  sizing absolute bottom20 left0 flex items-end justify-between">
            <div className="mesasge fz14 lh20 fw500 text-black2">
                <div className="contact mb6">Contact us</div>
                <div className="contace-email">wenova.inc@outlook.com</div>
            </div>
            <div className="right-box tar">
                <div className="top-link fz14 lh20 fw400 mb6">
                    <Link to="/termsOfService" className="text-black2 hover-primary mr40">Terms of Service</Link>
                    <Link to="/privacy" className="text-black2 hover-primary">Privacy Policy</Link>
                </div>
                <div className="copyright fz14 lh20 text-grey fw400">
                    Copyright © 2023 novmic.com All Rights Reserved.
                </div>
            </div>
        </div>
    )
}
export default Footer;
