import React from 'react'
const Page = (props) => {
    return (
        <div className="page pad-lg pt40 pb60">
            <div className="wrapper">
                <div className="logo">
                    <img src={require('@/assets/images/web-logo.png').default} alt="" />
                </div>
                <div className="title">用戶協議</div>
            </div>
            <div className="f-line"></div>
            <div className="info fz16 lh30">
                <div className="content">
                    <div className="t fw600 fz18 mt28">導言</div>
                    <div className="cont">
                        <p>歡迎您使用HiReader小說漫畫軟件及服務！</p>
                        <p> 為使用“HiReader小說漫畫”平臺（以下稱為“本平臺”）提供的HiReader小說漫畫軟件及服務（以下簡稱“本軟件”、“七貓免費小說”或“本服務”），您應當閱讀並遵守《HiReader小說漫畫用戶服務協議》（以下簡稱“本協議”）以及HiReader小說漫畫服務的各項制度規範等。</p>
                        <p>請您務必審慎閱讀、充分理解各條款內容，特別是免除或限制責任的相應條款，以及開通或使用某項服務的單獨協議，並選擇接受或不接受。限制、免責條款可能以加粗形式提示您註意。 除非您已閱讀並接受本協議所有條款，否則您無權使用本軟件及相關服務。您的下載、安裝、使用、獲取賬號、登錄等行為（以下統稱為“使用”）即視為您已閱讀並同意上述協議的約束。 您有違反本協議的任何行為時，本平臺有權依照違反情況，隨時單方限制、中止或終止向您提供本服務，並有權追究您的相關責任。 如果您未滿18周歲，請在監護人的陪同下閱讀本協議及其他上述協議，並特別註意未成年人使用條款。</p>
                    </div>
                </div>
                <div className="content">
                    <div className="t fw600 fz18 mt28">一、特別提示</div>
                    <div className="cont">
                        <p>本平臺同意按照本協議的規定及其不時發布的操作規則提供基於互聯網的相關服務(以下稱“網絡服務”)。為獲得本服務，用戶同意本協議的全部條款並通過運營商正規渠道獲取的手機號碼進行賬號註冊。註冊過程中需進行短信驗證並綁定一個有效的手機號碼，點擊“登錄”按鈕即表示用戶已經詳細閱讀了本協議的內容，同意遵守本協議的約定。用戶不應再以不了解本協議內容為由拒絕履行本協議。本平臺有權對本協議隨時進行修改與更新，並在相關官方網站公告新協議內容，而不另行對用戶進行個別通知。修改後的服務協議一旦在頁面上公布即有效代替原來的服務協議。請用戶隨時造訪本軟件內“賬號登錄”界面以及“關於我們”頁面查看本協議的最新版本。用戶在使用本平臺提供的各項服務之前，應仔細閱讀本服務協議，如用戶不同意本服務協議及/或隨時對其的修改，用戶可以主動取消本平臺提供的網絡服務。</p>
                    </div>
                </div>

                <div className="content">
                    <div className="t fw600 fz18 mt28">二、定義</div>
                    <div className="cont">
                        <p>1. HiReader小說漫畫軟件：是指本軟件所屬公司（以下簡稱“公司”）向用戶提供的讀書工具，支持軟件內置書城書籍的閱讀，同時為用戶提供包括但不限於書籍推薦、好友分享等功能或內容的軟件許可及服務的客戶端軟件，在本協議中簡稱“HiReader小說漫畫”。</p>
                        <p> 2. 用戶：是指啟用、瀏覽或上傳數據至HiReader小說漫畫服務的用戶，在本協議中更多地稱為“您”。</p>
                        <p> 3. 其他用戶：是指除用戶本人外與HiReader小說漫畫服務相關的其他HiReader小說漫畫用戶。</p>
                    </div>
                </div>

                <div className="content">
                    <div className="t fw600 fz18 mt28">三、協議的範圍</div>
                    <div className="cont">
                        <p>1. 本協議是您與本平臺之間關於用戶下載、安裝、使用本軟件，以及使用七貓免費小說相關服務所訂立的協議。</p>
                        <p>2. 本協議內容同時包括本平臺可能不斷發布的關於本服務的相關協議、服務聲明、業務規則及公告指引等內容（包括但不限於《HiReader小說漫畫用戶個人信息保護及隱私政策協議》等）。上述內容一經正式發布，即為本協議不可分割的組成部分，您同樣應當遵守。</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Page
