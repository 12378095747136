import React from 'react'
const Page = (props) => {
    return (
        <div className="page pad-lg pt40 pb60">
            <div className="wrapper">
                {/*<div className="logo">*/}
                {/*    <img src={require('@/assets/images/web-logo.png').default} alt="" />*/}
                {/*</div>*/}
                <div className="title">Privacy Policy</div>
            </div>
            <div className="f-line"></div>

            <div className="wrap fz16 lh30 mt40">
                <p>How Shepherd Novel uses relevant data when you use a Shepherd Novel partner's website or app.</p>
                <p className="bold">Privacy Policy</p>
                <p>The novmic.com Privacy Policy describes how novmic.com treat personal information when you use novmic.com services.</p>
                <p className="bold">Data Collection</p>
                <p>novmic.com may collect limited non-personally identifying information your browser makes available whenever you visit a website. This log information may include but not limited to your Internet Protocol address, browser type, browser language, the date and time of your query and one or more cookies that may uniquely identify your browser. We use this information to operate, develop and improve our services.</p>
                <p>Some of our services require you to register for an account. novmic.com asks you for some personal information in order to create an account (typically your name, email address and a password for your account) and we will use that information to provide the service.</p>
                <p className="bold">Cookies</p>
                <p>A "cookie" is a small file containing a string of characters that is sent to your computer when you visit a website. We use cookies to improve the quality of our service and to better understand how people interact with us. novmic.com does this by storing user preferences in cookies. Most browsers are initially set up to accept cookies. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, novmic.com features or services may not function properly without cookies.</p>
                <p>As a result of advertising on your website, third parties may place and read cookies on the user's browser, or they may use network beacons to get mobile phone information</p>
                <p className="bold">Information Sharing</p>

                <p>We do not rent or sell your personally identifying information to other companies or individuals, unless we have your consent. We may share such information in any of the following limited circumstances:We have your consent.</p>

                <p>We conclude that we are required by law or have a good faith belief that access, preservation or disclosure of such information is reasonably necessary to protect the rights, property or safety of novmic.com, its users or the public.</p>

                <p className="bold">Information Security</p>
                <p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data.</p>
                <p>We restrict access to your personally identifying information to site staffs who need to know that information in order to operate, develop or improve our services.</p>

                <p className="bold">Updating your Information</p>
                <p>We provide mechanisms for updating and correcting your personally identifying information for many of our services. For more information, please see the help pages for each service.</p>

                <p className="bold">Third-party Advertisers, Links to Other Websites</p>
                <p>novmic.com allows other companies, called third-party ad servers or ad networks, to serve advertisements within novmic.com. These third-party ad servers or ad networks use technology to send, directly to your browser, the advertisements and links that appear on novmic.com. They automatically receive your IP address when this happens. They may also use other technologies (such as cookies, JavaScript, or Web Beacons) to measure the effectiveness of their advertisements and to personalize the advertising content you see.</p>
                <p>novmic.com does not provide any personally identifiable information to these third-party ad servers or ad networks without your consent or except as part of a specific program or feature for which you will have the ability to opt-in or opt-out.</p>
                <p>You should consult the respective privacy policies of these third-party ad servers or ad networks for more information on their practices and for instructions on how to opt-out of certain practices. novmic.com’s privacy policy does not apply to, and we cannot control the activities of, such other advertisers or websites. Any data obtained by third-party ad servers subsequently shared with Some novels.com is maintained and dealt with by Some novels.com in accordance with this privacy policy.</p>
                <p>E.g : If you log in to Shepherd Novel with your facebook account, when you remove the Shepherd Novel app from your device, you can do this by going to your Facebook profile and clicking the Send Request button on the Settings & Privacy > Settings > Apps and Websites page, to requests the Shepherd Novel delete your data.</p>
                <p>Shepherd Novel’s privacy policy does not apply to, and we cannot control the activities of, such other advertisers or websites. Any data obtained by third-party ad servers subsequently shared with Shepherd Novel is maintained and dealt with by Shepherd Novel in accordance with this privacy policy.</p>
            </div>
        </div>
    )
}
export default Page
