import React,{useEffect,useState,useRef} from 'react'
import { useTranslation } from 'react-i18next'
import Lang from '@/components/Lang'
import Logo from '@/components/Logo'
import Footer from  '@/components/Footer'
import desc from '@/assets/images/desc.png'
import googlePlay from '@/assets/images/googlePlay.png'
import googlePlayCheck from '@/assets/images/googlePlay-check.png'
import appStore from '@/assets/images/appStore.png'
import appStoreCheck from '@/assets/images/appStore-check.png'
import Carousel from '@/components/Carousel'
import enDes from '@/assets/images/1000+.png'
import className from 'classnames'
const Index = () => {
    const [CarouselData,setCarouselData]=useState([]);
    const carouselRef=useRef(null);

    const imgData=[require('@/assets/images/i1.png').default,require('@/assets/images/i2.png').default,require('@/assets/images/i3.png').default];
    const EnimgData=[require('@/assets/images/e1.png').default,require('@/assets/images/e2.png').default,require('@/assets/images/e3.png').default];


    let { t,i18n } = useTranslation()
    // let language=i18n.language;

    let language='en';



    useEffect(() => {
        let isZh=(language === 'zh' || language.includes('zh'));
        setCarouselData(!isZh ? EnimgData :  imgData)
        if(carouselRef && carouselRef.current){
            carouselRef.current.resetState();
        }
    }, [language]);


    const downloadApp=(platform)=>{
        let url='';
        if(platform === 'ios'){
            url='https://apps.apple.com/us/app/shepherd-best-fiction-webnovel/id1420995439'
        }else if(platform === 'android'){
            url=!(language === 'zh' || language.includes('zh')) ? 'https://play.google.com/store/apps/details?id=com.pailing.app' : 'https://play.google.com/store/apps/details?id=com.novel.comics'
        }
        window.open(url)
    }


    return (
        <div className="window-height relative page-home window-width">
            <div className="flex justify-between pad sizing items-center absolute top20 full-width sizing">
                <Logo />
                {/*<Lang />*/}
            </div>
            <div className="flex window-height items-center window-width pad sizing">
                <div className="home-box flex justify-between window-width">
                    <div className={className('left',{'en':!(language === 'zh' || language.includes('zh'))})}>
                        <img src={(language === 'zh' || language.includes('zh')) ? desc : enDes} alt=""/>
                        <div className="download flex full-width justify-between">
                            {/*<div className="google download-item" onClick={()=>downloadApp('android')}>*/}
                            {/*    <img src={googlePlay} alt="" />*/}
                            {/*    <img src={googlePlayCheck} className="check" alt="" />*/}
                            {/*</div>*/}
                            <div className="appStore download-item" onClick={()=>downloadApp('ios')}>
                                <img src={appStore} alt="" />
                                <img src={appStoreCheck} className="check" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <Carousel data={CarouselData} ref={carouselRef} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Index;
