import React from 'react';
import ReactDOM from 'react-dom';
import '@/assets/css/reset.css';
import '@/assets/css/common.scss'
import '@/assets/css/flex.scss'
import '@/assets/css/style.scss'
import '@/utils/i18n'

import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);


// ((function(){
//     if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || /(Android)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
//         let href=window.location.href,origin=window.location.origin;
//         let i=href.slice(origin.length);
//         window.location.href = `https://m.novmic.com${i}`;
//     }
// }))()
