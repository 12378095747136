import React, { useContext } from 'react'
import { TranslateContext } from '../context/translate'
import TermsOfServiceCn from './components/termsOfServiceCn'
import TermsOfServiceEn from './components/termsOfServiceEn'
const Page = (props) => {
    const tranlsate= 'en';
    return (
        <div>
            {
                (tranlsate === 'zh' || tranlsate.includes('zh')) ? <TermsOfServiceCn /> : <TermsOfServiceEn />
            }
        </div>
    )
}
export default Page
